import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import { useRankingEventFilters, useRankingFilters, useSportEvent } from 'micro-site/queries/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import 'constants/DrawEventForm';
import { form, tournament } from 'constants/DrawEventForm/index';
import {
  // usePointsMedals,
  useRankingTableList,
  useWinnerTable,
} from '../../queries/hooks.js';
import MainPage from './components/MainPage';
import RankingTable from './components/RankingTable';
import WinnerList from './components/WinnerList';
// import { Helmet } from 'react-helmet';
import { getDateInMonthDateYear, getTimeIn12HourFormat } from 'constants/DateFunctions';

export default function index() {
  const history = useHistory();
  const { search } = useLocation();
  const queries = useMemo(() => new URLSearchParams(search), [search]);
  const isScrolled = useRef(false);

  useEffect(() => {
    if (isScrolled.current) {
      return;
    }

    const toScroll = queries.get('selected');
    const idToScroll = toScroll && document.getElementById(toScroll || '');
    if (idToScroll) {
      const { top } = idToScroll.getBoundingClientRect();
      // idToScroll && idToScroll.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({ top: top - 100 });
      isScrolled.current = true;
    }
  });
  // const pointsMedalsQueryData = usePointsMedals({
  //   tournament: '1234',
  // });

  const [sport, setSport] = useState(null);
  const [subSport, setSubSport] = useState(null);

  const rankingTableListPreviewQueryData = useRankingTableList({
    tournament: history.location.pathname.split('/')[2],
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    event: '',
    medal: '',
    limit: 25,
    round: '',
  });

  const rankingTableListFullQueryData = useRankingTableList({
    tournament: history.location.pathname.split('/')[2],
    limit: 25,
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    event: '',
    medal: '',
    round: '',
  });
  const rankingTableFiltersQueryData = useRankingFilters({
    tournamentId: history.location.pathname.split('/')[2],
  });
  const rankingTableFiltersEventQueryData = useRankingEventFilters({
    tournamentId: history.location.pathname.split('/')[2],
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
  });
  const winnerTableQueryData = useWinnerTable({
    tournament: history.location.pathname.split('/')[2],
    recent: 'True',
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    subSport: subSport ? (subSport === 'All' ? '' : subSport) : '',
  });

  const winnerTableFullQueryData = useWinnerTable({
    tournament: history.location.pathname.split('/')[2],
    limit: 25,
    recent: 'False',
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    subSport: subSport ? (subSport === 'All' ? '' : subSport) : '',
  });

  useEffect(() => {
    // console.log('changed', sport);
    winnerTableFullQueryData.refetch();
  }, [sport]);

  const winnerColumns = [
    {
      key: 'athlete_sfa_id',
      label: 'Athlete',
      component: 'OpponentColumn',
    },
    {
      key: 'sport',
      label: 'Sport',
      component: 'TextColumn',
    },
    {
      key: 'sub_category',
      label: 'Category',
      component: 'TextColumn',
    },
    {
      key: 'sport_event',
      label: 'Gender',
      component: 'TextAndImage',
    },
    {
      key: 'age_group',
      label: 'Age Category',
      component: 'TextColumn',
    },
    {
      key: 'medal',
      label: 'Result',
      component: 'TextAndImage',
    },
  ];

  const columnsTable = [
    {
      key: 'angle_bottom',
      label: '',
      component: 'AngleBottomColumn',
    },
    {
      key: 'rank',
      label: 'Rank',
      component: 'TextColumn',
    },
    {
      key: 'institution',
      label: 'School Name',
      component: 'TextAndImage',
    },
    {
      key: 'gold',
      label: 'Gold',
      component: 'TextColumn',
    },
    {
      key: 'silver',
      label: 'Silver',
      component: 'TextColumn',
    },
    {
      key: 'bronze',
      label: 'Bronze',
      component: 'TextColumn',
    },
    {
      key: 'points',
      label: 'Points',
      component: 'TextColumn',
    },
  ];

  // const [medal, setMedal] = useState(null);
  const [screen, setScreen] = useState('main_page');
  const [categoryList, setCategoryList] = useState([]);
  const [listEventData, setListEventData] = useState([]);

  const [params, setParams] = useState('');
  const sportEventQueryData = useSportEvent({
    tournament: tournament,
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });

  const handleDrawFormat = (schema) => {
    setParams(schema);
  };

  useEffect(() => {
    sportEventQueryData.refetch();
  }, [params]);

  useEffect(() => {
    // eslint-disable-next-line

    setCategoryList(['All Sports', ...(rankingTableFiltersQueryData?.data?.message || [])]);

    // setCategoryList(['All Sports', ...rankingTableBackUpQueryData.data.at(0).all_sports.sort()]);
  }, [rankingTableFiltersQueryData.data, rankingTableFiltersQueryData.isFetched]);

  useEffect(() => {
    // eslint-disable-next-line
    const collectedList = [
      'All',
      ...(rankingTableFiltersEventQueryData?.data?.message
        .filter((ele) => ele.sub_category !== null)
        .map((ele) => ele.sub_category) || []),
    ];

    setListEventData([...new Set(collectedList)]);

    // setCategoryList(['All Sports', ...rankingTableBackUpQueryData.data.at(0).all_sports.sort()]);
  }, [rankingTableFiltersEventQueryData.data, rankingTableFiltersEventQueryData.isFetched]);

  useEffect(() => {
    setSubSport(null);
  }, [sport]);

  // console.log(rankingTableFiltersQueryData, categoryList);
  // useEffect(() => {
  //   rankingTableListBackUpQueryData.data ? setCategoryList(rankingTableListBackUpQueryData.data[0].all_sports) : [];
  // }, [rankingTableListBackUpFullQueryData.data]);

  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data?.[0].digital_scoring_format?.toLowerCase()}/${encodeURIComponent(
          sportEventQueryData.data?.[0].sport_event
        )}`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  const handleSwitch = (screen) => {
    switch (screen) {
      case 'main_page':
        return (
          <MainPage
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            subSport={subSport}
            setSubSport={setSubSport}
            listEventData={listEventData}
            rankingTableListPreviewQueryData={rankingTableListPreviewQueryData}
            rankingTableListFullQueryData={rankingTableListFullQueryData}
            winnerTableQueryData={winnerTableQueryData}
            winnerTableFullQueryData={winnerTableFullQueryData}
            form={form}
            columnsTable={columnsTable}
            setScreen={setScreen}
            sportEventQueryData={sportEventQueryData}
            handleDrawFormat={handleDrawFormat}
            rankingFilters={rankingTableFiltersQueryData}
          />
        );
      case 'ranking_table':
        return (
          <>
            <h1 className="py-2 text-right">
              Last Updated On{' '}
              {getDateInMonthDateYear(rankingTableListFullQueryData.data.pages[0].last_update)}{' '}
              {getTimeIn12HourFormat(rankingTableListFullQueryData.data.pages[0].last_update)}
            </h1>
            <RankingTable
              sport={sport}
              setSport={setSport}
              categoryList={categoryList}
              rankingTableListFullQueryData={rankingTableListFullQueryData}
              columnsTable={columnsTable}
              setScreen={setScreen}
              rankingFilters={rankingTableFiltersQueryData}
            />
          </>
        );
      case 'winner_list':
        return (
          <WinnerList
            sport={sport}
            setSport={setSport}
            subSport={subSport}
            setSubSport={setSubSport}
            listEventData={listEventData}
            categoryList={categoryList}
            winnerTableFullQueryData={winnerTableFullQueryData}
            setScreen={setScreen}
            winnerColumns={winnerColumns}
          />
        );

      default:
        return (
          <MainPage
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            subSport={subSport}
            setSubSport={setSubSport}
            listEventData={listEventData}
            rankingTableListPreviewQueryData={rankingTableListPreviewQueryData}
            winnerTableQueryData={winnerTableQueryData}
            winnerTableFullQueryData={winnerTableFullQueryData}
            form={form}
            columnsTable={columnsTable}
            setScreen={setScreen}
            rankingFilters={rankingTableFiltersQueryData}
          />
        );
    }
  };

  // Driver Code
  return (
    <>
      {/* <Helmet>
        <title>Results | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check the RESULTS now! Athletics, Badminton, Football, Hockey, Kabaddi and 20 MORE SPORTS are now LIVE at #KIYG2021! Keep checking for LATEST UPDATES."
        />
        <meta property="og:title" content="Results | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check the RESULTS now! Athletics, Badminton, Football, Hockey, Kabaddi and 20 MORE SPORTS are now LIVE at #KIYG2021! Keep checking for LATEST UPDATES."
        />
      </Helmet> */}
      {handleSwitch(screen)}
    </>
  );
}
